<template>
  <div>
    <b-tabs>
      <b-tab title="Lead Chart"> 
<LeadChart/>
      </b-tab>


      <b-tab title="Lead List">
<MyLeads/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import LeadChart from "./LeadChart.vue";
import MyLeads from "./MyLeads.vue";

export default {
  components: {
    BTabs,
    BTab,
    LeadChart,
    MyLeads
  },

  data() {
    return {};
  },

  methods: {},
};
</script>
